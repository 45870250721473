/* ==========================================================================
   Home Page Layout
   ========================================================================== */

.front {
  .region-content {
    width: 100%;
    padding: 0;
    margin: 0 auto;
    .region-content-inner {
      margin: auto;
      padding: 2em;
      @include breakpoint($tablet){
        max-width:$tablet;
      }
      @include breakpoint($desk){
        max-width:$desk;
      }
      @include breakpoint($wide){
        max-width:$wide;
      }
    }
  }
}


.front {
  // .region-content {
  //   @extend %inner-region;
  //   @extend %clearfix;
  // }
  // each sidebar region block should extend to full width of screen
  .region-sidebar-first {
    .block__title,
    .block__content {
      @extend %inner-region;
      @extend %clearfix;
    }
  }
  .region-sidebar-second {
    .block-inner {
      @extend %inner-region;
      @extend %clearfix;
    }
  }
}

