/* ==========================================================================
   Footer Blocks
   ========================================================================== */

.region-footer {
  .block {
    @include breakpoint($bp-narrow) {
      text-align: center;
    }
  }
}

/**
 * Mission Statement
 */
.block--footer-mission {
  p {
    position: relative;
    padding: 1em 10%;
    font-size: em(26);
    @include webfont-serif;
    font-style: italic;
    text-align: center;
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      height: 1px;
      width: 40%;
      margin-left: -20%;
      background-color: rgba(#ebf7f6, .2);
    }
    &:before {
      top: 0;
    }
    &:after {
      bottom: 0;
    }
  }
}

/**
 * Contact Info
 */
.block--footer-contact-info {
  .block__title {
    @include webfont-serif;
    font-weight: $font-weight-normal;
    font-size: em(19);
    color: $color-text-footer;
    margin: 0;
  }
  .block__content {
    font-size: em(14);
    a {
      color: $color-text-footer;
    }
  }
}

/**
 * Site Credit
 */
.block--footer-credit {
  .block__content {
    font-size: em(9);
    text-transform: uppercase;
    a {
      color: $color-text-footer;
    }
  }
}

/**
 * Accreditation Seal
 */
.block--accreditation {
  @include breakpoint($bp-middle-and-wide) {
    float: right;
  }
  .block__content {
    a {
      display: block;
      height: 100px;
      min-width: 100px;
      width: 100%;
      text-indent: -9999px;
      overflow: hidden;
      @include svg-bg('accreditation-seal');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      opacity: .3;
      &:hover,
      &:focus {
        opacity: .8;
      }
    }
  }
}

