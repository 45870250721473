/* ==========================================================================
   Teaser Styles
   ========================================================================== */




/* ==========================================================================
   Events
   ========================================================================== */

/**
 * Event Calendar Style Field
 */
.event-date {
  display: inline-block;
  border: 1px solid darken($white, 14);
  min-width: 5em;
  background-color: $color-background;
}
.event-date__month {
  display: block;
  text-align: center;
  @include webfont-sans;
  font-size: em(14);
  text-transform: uppercase;
  margin-bottom: .75em;
  background-color: #f3f3f3;
}
.event-date__day {
  display: block;
  text-align: center;
  @include webfont-serif;
  font-size: em(48);
  font-style: italic;
  line-height: 1;
}

/**
 * Member Event 
 */
.member-event-flag {
  margin: 0 0.75em .25em 0;
  padding: .325em 1em;
  display: inline-block;
  font-size: em(10);
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: .12em;
  color: $white;
  background-color: $blue;
}

/**
 * Event List
 */

.teaser-list--events {
  list-style: none;
  margin-bottom: 1.5em;
  padding: 0;

  li {
    @extend %clearfix;
    position: relative;
    margin: 0 0 1.5em;
    padding-top: 5.5em;
    @include breakpoint($bp-middle-and-wide) {
      padding: 0 0 0 6.5em;
    }
  }

  .event-date {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    @include breakpoint($bp-middle-and-wide) {
      display: block;
    } 
  }

  .field-event-image {
    margin-top: -5.5em;
    @include breakpoint($bp-middle-and-wide) {
      width: 25%;
      float: left;
      margin: 0 1em 0 0;
      & ~ * {
        margin-left: 30%;
      } 
    }
  }

  figcaption {
    display: none;
  }

  .field-event-date,
  .field-location {
    font-size: em(12);
    text-transform: uppercase;
    &:before {
      content: '';
      margin-right: .5em;
      display: inline-block;
      width: 12px;
      height: 12px;
      position: relative;
      top: 2px;
      background-position: 50% 50%;
    }
  }
  .field-event-date:before {
    @include svg-bg('icon-time');
  }
  .field-location:before {
    @include svg-bg('icon-location');
  }


  .field-short-summary {
    @include webfont-serif;
    font-size: em(16);
  }
}


/* ==========================================================================
   Preserves
   ========================================================================== */


.teaser-list--preserves {
  @extend %clearfix;
  list-style: none;
  margin-bottom: 1.5em;
  padding: 0;
  
  li {
    float: left;
    margin: 0 em(20) em(20) 0;
    position: relative;
    // Link containing image
    & > a {
      display: inline-block;
    }
  }

  .preserve-title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1.5em 1em 0.75em;
    margin: 0;
    font-size: em(13);
    @include webfont-sans;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    letter-spacing: .08em;
    background-image: linear-gradient(transparent, rgba(0,0,0,.85));
    a {
      color: $white;
      display: block;
      margin-right: 3em; // space for info link
    }
  }
  .preserve-more-info {
    @include rgba-bg($tan-light, .9);
    padding: 1.5em;
    font-size: em(13);
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 1;
    transition: opacity 0.2s ease-in;

    &.is-hidden {
      opacity: 0;
      display: block;
    }
  }
  .more-link {
    margin: 0;
    text-transform: uppercase;
    font-weight: bold;
  }
  .togglePreserveInfo {
    position: absolute;
    z-index: 5;
    bottom: .6em;
    right: .75em;
    height: 2em;
    width: 2em;
    line-height: 2.2;
    border-radius: 5em;
    border: 2px solid rgba($white, .5);
    color: $white;
    font-style: italic;
    @include webfont-serif;
    text-align: center;
    font-size: em(18);
    
    &:hover,
    &:focus {
      border-color: $white;
    }
  }
}

@supports (display: grid) {

  .teaser-list--preserves {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(auto, 300px));
    grid-gap: 1.5rem;
  }
  .teaser-list--preserves:before,
  .teaser-list--preserves:after {
    display: none;
  }
  .teaser-list--preserves li {
    float: none;
    margin: 0;
  }

}

/* ==========================================================================
   Support Page
   ========================================================================== */
.view-support-page {
  h2.preserve-title {
    color:$white;
  }
}

/* ==========================================================================
   Featured Links
   ========================================================================== */
.teaser-list--featured-links {
  list-style: none;
  padding: 0;
  @include breakpoint($bp-middle-and-wide) {
    display: flex;
    width: 100%;
  }
  li {
    position: relative;
    background-color: $tan-light;
    margin-bottom: 2em;
    padding-bottom: 4em; // space for link
    @include breakpoint($bp-middle-and-wide) {
      width: 31%;
      &:nth-child(2) {
        margin-left: 3.5%;
        margin-right: 3.5%;
      }
    }
  }
  img {
    width: 100%;
  }
  figcaption {
    display: none;
  }
  .featured-link-title {
    text-align: center;
    font-size: em(24);
  }
  .field-body {
    padding: 0 em(20);
  }
  .field-featured-link {
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    a {
      @extend %button--teal;
    }
  }
}

/* ==========================================================================
   Staff & Board
   ========================================================================== */

.view-people {
  .views-row {
    margin-bottom:1.5em;
    padding-bottom:1.5em;
    border-bottom: 1px solid $teal;
    &:last-of-type {
      border: 0;
    }
    @include breakpoint($desk){
      display: flex;
      justify-content: flex-start;
    }
  }
  .image-container {
    flex: 0 0 30%;
    margin-right:1.5em;
  }
  h2 {
    color: $black;
  }
  .envelope {
    text-indent: -9999px;
    @include svg-bg('envelope');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    width: 41px;
    height: 26px;
    display: inline-block;
    vertical-align: text-bottom;
  }
  .people-facts {
    margin-right: 1.5em;
    padding-right: 1.5em;
    margin-bottom: 1.5em;
    @include breakpoint($desk){
      border-right: 1px solid $brown-mid;
    }
    &.origin {
      strong {
        &::before {
          content:"";
          @include svg-bg('map-marker');
          background-repeat: no-repeat;
          background-position: 0 1px;
          background-size: cover;
          width: 14px;
          height: 22px;
          display: inline-block;
          margin-right: .5em;
        }
      }
      span {
        padding-left: 25px;
        display: block
      }
    }
    &.trail {
      border:none;
      strong {
        &::before {
          content:"";
          @include svg-bg('boot-brown-dark');
          background-repeat: no-repeat;
          background-position: left bottom;
          background-size: contain;
          width: 24px;
          height: 22px;
          display: inline-block;
          margin-right: .5em;
        }
      }
      span {
        padding-left:30px;
        display:block;
      }
    }
    &.gets-me-out {
      border: none;
      margin-bottom: .2em;
      strong {
        margin-bottom: 0;
        &::before {
          content:"";
          @include svg-bg('leaf');
          width: 22px;
          background-repeat: no-repeat;
          background-position: 0 1px;
          background-size: cover;
          height: 22px;
          display: inline-block;
          margin-right: .2em;
        }
      }
      span {
        padding-left: 30px;
        display: block;
      }
    }
    strong {
      font-size: 1.4em;
      color: $teal;
      margin-right: .5em;
    }
  }
  .people-bio {
    font-size: 1.4em;
    color: $teal;
    margin-right: .5em;
    font-weight: bold;
    margin: 1em 0 1em .2em;
  }
}

.people-flex {
  @include breakpoint($desk){
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.teaser-list--people {
  list-style: none;
  padding: 0;
  li {
    @extend %clearfix;
    margin-bottom: 1.5em;
  }
  .field-people-position {
    @include webfont-serif;
    font-style: italic;
    margin-bottom: .5em;
  }

  .field-people-email,
  .field-people-phone {
    font-size: .9em;
  }

  .field-body {
    font-size: .9em;
  }

  .field-people-river-location {
    margin: -0.75em 0 0.5em;
    font-size: .75em;
    text-transform: uppercase;
    letter-spacing: .06em;
  }
}

/* ==========================================================================
   FAQs
   ========================================================================== */
.list--faq {
  list-style: none;
  padding: 0;
  li {
    margin-bottom: 1.5em;
    padding-left: 1.75em;
    position: relative; // context for icon in :before
    &.is-open {
      .faq-link:before {
        content: 'X';
      }
    }
  }
  .faq-link {
    &:before {
      content: '?';
      display: block;
      position: absolute;
      top: 0.25em;
      left: 0;
      font-size: .75em;
      font-weight: normal;
      height: 1.5em;
      width: 1.5em;
      border-radius: 1em;
      text-align: center;
      line-height: 1.3;
      color: $color-link;
      // background-color: $color-link;
      border: 1px solid $color-link;
    }
  }
  .field-body {
    @include webfont-serif;
    font-size: em(18);
  }
}

/* ==========================================================================
   News
   ========================================================================== */

.teaser-list--news {
  list-style: none;
  padding: 0;
  @extend %clearfix;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 1.5em;
    flex-wrap: wrap;

    @include breakpoint($bp-middle-and-wide) { 
      flex-wrap: nowrap;
    }
  }

  .field-images {
    max-width: 22.5%;
    margin-right: 1.25em;
    margin-bottom: 0.5em;
    flex-shrink: 0;
    @include breakpoint($bp-middle-and-wide) { 
      margin-bottom: 0;
    }

    figcaption {
      @include element-invisible;
    }

    a {
      overflow: hidden;
      border-radius: 100%;
      display: block;
      border: 3px solid rgba($color-link, 0);

      &:hover,
      &:focus {
        border-color: $color-link;
      }
    }

    img {
      width: 100%;
    }
  }

  .field-news-categories {
    margin: 0;
    text-transform: uppercase;
    font-size: em(10);
    letter-spacing: .1em;
    color: $brown-light;
  }

  .teaser-title {
    margin-bottom: .125em;
  }

  .field-post-date {
    font-size: em(12);
    margin-bottom: 0.25em;
  }

  .field-short-summary {
    margin-bottom: 0;
  }
}

/* ==========================================================================
   Projects
   ========================================================================== */

.teaser-list--projects {
  list-style: none;
  padding: 0;
  @extend %clearfix;

  li {
    margin-bottom: 1.5em;
    @include breakpoint($bp-middle-and-wide) { 
      float: left;
      width: 45%;
      margin-right: 5%;
      &:nth-child(odd) {
        clear: both;
      }
    }
  }
  .field-project-image{
    margin-bottom: 0.5em;
  }
  figcaption {
    display: none; 
  }
  .project-title {
    margin-bottom: .25em;
    line-height: 1.1;
	max-width: 300px;
  }
  .field-project-summary {
    margin-bottom: 0;
    font-size: em(14);
	max-width: 300px;
  }
}

.view-projects {
  margin-top: 1.5em;
  clear: both; // needs to clear above content which can contain a floated flexslider or image

  // Grouped by project type
  h2 {
    text-transform: capitalize; // uses field values which aren't capitalized
  }
}


/* ==========================================================================
   Jobs
   ========================================================================== */

.teaser-list--jobs {
  list-style: none;
  padding: 0;

  li {
    margin-bottom: 1.5em;
  }
  .teaser-title {
    margin-bottom: 0;
  }
  .field-short-summary {
    margin-bottom: 0;
  }
  .field-job-deadline {
    font-style: italic;
    @include webfont-serif;
  }
}

/* ==========================================================================
   TILT by the Numbers
   ========================================================================== */

.numbers--grid {
  @extend %clearfix;
  list-style: none;
  margin-bottom: 1.5em;
  padding: 2em 5% 0;
  border-top: 4px double lighten($brown-light, 25%);
  border-bottom: 4px double lighten($brown-light, 25%);

  li {
    margin-bottom: 3em;
    text-align: center;
    // 2 up
    @include breakpoint($bp-middle) { 
      float: left;
      width: 45%;
      margin-right: 5%;
      &:nth-child(odd) {
        clear: both;
      }
    }
    // 4 up
    @include breakpoint($bp-wide) { 
      float: left;
      width: 22%;
      margin-left: 3%;
      &:nth-child(4n+1) {
        clear: both;
        margin-left: 0;
      }
    }
  }

  .numbers--link {
    color: $brown-light;
    &:hover,
    &:focus {
      opacity: 0.8;
      color: $brown-superlight;
    }
  }

  .numbers--caption {
    margin: 1em 0;
  }

  .numbers--number {
    margin-bottom: .1em;
    display: block;
    font-size: 3em;
    line-height: 1;
    font-weight: bold;
  }

  .numbers--text {
    display: block;
    font-size: 1.2em;
    line-height: 1.2;
    font-style: italic;
    @include webfont-serif;
  }

}

/* ==========================================================================
   Annual Reports
   ========================================================================== */

.list--annual-reports {
  list-style: none;
  padding: 0;
  @extend %clearfix;

  li {
    margin-bottom: 1.5em;
    @include breakpoint($bp-middle-and-wide) { 
      float: left;
      width: 45%;
      margin-right: 5%;
      &:nth-child(odd) {
        clear: both;
      }
    }
  }
}



