/* ==========================================================================
   Preserve Styles
   ========================================================================== */

.block--preserves-map {
  margin-bottom: 2.5em;
}

.leaflet-container {
  border: .325em solid $tan-light;
}

.leaflet-interactive {
  stroke: $orange;
  fill: $orange;
}

