/* ==========================================================================
   Typography
   ========================================================================== */


/**
 * Root html element
 * (set defaults for root font size and line height)
 */
html {
  font-family: $font-family-fallback; 
  font-size: 100% * ($base-font-size-value / 16); 
  line-height: $base-line-height-ratio;
  color: $color-text;
  // Class added to html when fonts are loaded
  &#{$fonts-loaded-class} {
    font-family: $font-family-default;
  }
}

/**
 * Headers
 */

h1 {
  margin: 0 0 0.5em;
  @include webfont-serif;
  font-size: em(40);
  font-weight: $font-weight-normal;
  line-height: 1.1;
}

h2 {
  @include webfont-sans;
  font-size: em(28);
  line-height: 1.2;
  color: $brown-light;
  margin: 0 0 0.5em;
}

h3 {
  @include webfont-sans;
  font-size: em(20);
  margin: 0 0 0.5em;
}

h4 {
  @include webfont-serif;
  text-transform: uppercase;
  letter-spacing: .08em;
  font-size: em(15);
  margin: 1em 0 0.25em;
}

h5 {
  margin: 0.5em 0;
}

h6 {
  margin: 0.5em 0;
}

/**
 * Paragraph
 */

p {
  margin: 0 0 1em;
}

/**
 * Lists
 */

ul {
  margin: 0 0 1em;
  padding: 0 0 0 1em;
}

ol {
  margin: 0 0 1em;
  padding: 0 0 0 1em;
}

/**
 * Quotes
 */

blockquote {
  margin: 1.5em auto 0;
  padding: 1.25em 1em;
  max-width: 36em;
  position: relative;
  text-align: center;
  @include webfont-serif;
  font-style: italic;
  font-size: 1.2em;

  // quote with photo
  &.with-photo {
    margin: 4.5em auto 0;
    padding: 3em 1em 1.25em;

    // wrapper for img
    .blockquote-photo {
      padding: 3px;
      width: 100px;
      height: 100px;
      overflow: hidden;
      content: '';
      position: absolute;
      top: -60px;
      left: 50%;
      background: white;
      margin-left: -50px;
      border-radius: 100px;
      border: 1px solid #f1e6dc;

      img {
        width: 100%;
      }
    }
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    height: 1px;
    width: 40%;
    margin-left: -20%;
    background-color: darken($tan-light, 5%);
  }
  &:before {
    top: 0;
  }
  &:after {
    bottom: 0;
  }

  cite {
    display: block;
    margin-top: -1em;
    font-size: 0.8em;
    font-style: normal;
    &:before {
      content: "\2014";
    }
  }
}