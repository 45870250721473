/* ==========================================================================
   Blocks
   ========================================================================== */

/**
 * Home Intro Block
 */
.block--home-intro {
  padding-top: 100px;
  padding-bottom: 100px;

  .block__title {
    color: $white;
    @include webfont-serif;
    font-weight: $font-weight-bold;
    font-style: italic;
    text-align: center;
    font-size: em(42);
    line-height: 1.1;
    text-shadow: 0 0 5px rgba(#000,.2);
    @include breakpoint($bp-middle-and-wide) {
      font-size: em(48);
    }
  }
  .block__content {
    text-align: center;
    font-size: em(13);
    a {
      display: inline-block;
      margin: .25em .75em;
      padding: 1em 2em;
      color: $white;
      @include rgba-bg($black, .6);
      border-radius: .5em;
      text-transform: uppercase;
      letter-spacing: .08em;
      font-weight: $font-weight-bold;
      &:hover,
      &:focus {
        background-color: rgba(#000, .9);
      }
    }
  }
}


/**
 * Intro Links
 */

.block--intro-links {
 display: block;

  .block-title {
    margin-bottom: 1.125em;
    font-size: em(15);
    color: $blue;
    text-transform: uppercase;
    text-align: center;
    overflow: hidden;
    a {
      color: $blue;
      display: block;
      padding-top: em(80);
      background-repeat: no-repeat;
      background-position: center top;
    }
    span {
      position: relative;
      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        width: 20px;
        height: 2px;
        background-color: $blue;
      }
      &:before {
        right: 100%;
        margin-right: .5em;
      }
      &:after {
        left: 100%;
        margin-left: .5em;
      }
    }
  }
  .intro-links-item {
    margin: 0 0 .125em;
    font-size: em(30);
    line-height: 1.1;
    @include webfont-serif;
    text-align: center;
  }
  // See More link
  .more-link-wrapper {
    text-align: center;
  }
  .more-link {
    font-size: em(13);
    text-transform: uppercase;
    letter-spacing: .04em;
  }
}
// News Block
.block--intro-links-news .block-title a {
  @include svg-bg('TILT-logo-icons-01');
}
// Numbers Block
.block--intro-links-numbers .block-title a {
  @include svg-bg('TILT-logo-icons-02');
}
// FAQ Block
.block--intro-links-faq .block-title a {
  @include svg-bg('TILT-logo-icons-03');
}


/**
 * Featured Links Block
 */
.block--featured-links {
  padding: 3em 0 4em;
  background-color: $brown-dark;
  background-position: 50% 50%;
  overflow: hidden; // make sure lines next to title don't overflow container
  background-size: cover;
  @include breakpoint( 0 em(649) ) {
    background-image: image('featured-links-bg-700px.jpg');
  }
  @include breakpoint( em(650) em(1200) ) {
    background-image: image('featured-links-bg-1200px.jpg');
  }
  @include breakpoint( em(1201) ) {
    background-image: image('featured-links-bg-1800px.jpg');
  }
  
  .block__title {
    color: $white;
    text-align: center;
    font-size: em(42);
    margin-bottom: 1em;
    span {
      position: relative;
      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        width: 50%;
        height: 3px;
        background-color: rgba($white, .3);
      }
      &:before {
        right: 100%;
        margin-right: .5em;
      }
      &:after {
        left: 100%;
        margin-left: .5em;
      }
    }
  }
}


/**
 * TILT Kids Promo
 */
.block--tilt-kids-promo {
  position: relative;
  background-color: $color-background-tilt-kids;
  text-align: center;
  @include breakpoint($bp-tilt-kids-wide) {
    text-align: left;
  }

  .block-title {
    display: none;
  }

  .block__content {
    position: relative;
    padding-top: 160px;
    margin-top: 2em;
    padding-bottom: 3em;
    background-image: image('TILTKids_PrimaryLogo@2x.png');
    background-repeat: no-repeat;
    background-position: 50% top;
    background-size: 162px 148px;
    @include breakpoint($bp-tilt-kids-wide) {
      margin: 0;
      padding-left: 45%; // bg image space
      padding-right: 192px;
      padding-top: 65px;
      padding-bottom: 55px;
      background-image: image('TILTKids_PrimaryLogo@2x.png'), image('TILT-kids-bg@2x.jpg');
      background-position: right 50%, left 50%;
      background-size: 162px 148px , 40% auto;
    }


    p {
      position: relative;
      color: $brown-mid;
      margin-bottom: 0;
      padding-bottom: 0.75em;
      @include breakpoint($bp-tilt-kids-wide) {
        padding-right: 0.5em;
        border-right: 1px solid rgba($black, .15);
      }
      &:first-child {
        font-size: em(24);
        font-weight: $font-weight-bold;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
    a {
      @extend %button--brown;
    }
  }
}

