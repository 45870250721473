/* ==========================================================================
   Forms
   ========================================================================== */


/* Wrapper for a form element (or group of form elements) and its label. */
.form-item {
  margin: 0 0 1em;
  max-width: 100%;

  /* Pack groups of checkboxes and radio buttons closer together. */
  .form-checkboxes & ,
  .form-radios & {
    /* Drupal core uses "0.4em 0". */
    margin: 0;
  }

  /* Form items in a table. */
  tr & {
    margin: 0;
  }

  /* Highlight the form elements that caused a form submission error. */
  input.error,
  textarea.error,
  select.error {
    border: 1px solid #c00;
  }

  /* The descriptive help text (separate from the label). */
  .description {
    @include webfont-serif;
    font-style: italic;
    font-size: 0.9em;
    margin-top: 0.5em;
  }
}

.form-type-radio,
.form-type-checkbox {
  .description {
    margin-left: 2.4em;
  }
}

/* The part of the label that indicates a required field. */
.form-required {
  color: #c00;
}

label {
  display: block;
  font-weight: bold;
  /* Labels for radios and checkboxes. */
  &.option {
    display: inline;
    font-weight: normal;
  }
}

/**
 * Text Fields and Textareas
 */
.form-text,
.form-textarea {
  max-width: 100%;
  padding: 0.5em .75em;
  border-radius: .25em;
  border: 1px solid lighten($brown-light, 20);
  line-height: 1.3;
  background-color: $white;
  &:focus {
    border-color: $teal;
  }
}

/**
 * Select Elements
 */
.form-select {
  appearance: none;
  max-width: 100%;
  padding: 0.45em 1.5em 0.45em .75em;
  border-radius: .25em;
  border: 1px solid lighten($brown-light, 20);
  background-color: $white;
  background-image: image('menu-expanded.png');
  background-position: 98% 50%;
  background-position: right .5em top 50%;
  background-repeat: no-repeat;
  &:focus {
    border-color: $teal;
  }
}


/**
 * Submit Buttons
 */
.form-submit {
  @extend %button--teal;
}


/**
 * Additional help text on Kids Event spaces field
 */

div.field-name-field-registration-kid-name-age ~ div.form-item-count {
  .description:after {
    content: "Include children and adults.";
	margin-left: 2px;
  }
}


/**
 * Anonymous users shouldn't see row weight options
 */

.not-logged-in {
  .field-multiple-drag, .tabledrag-toggle-weight-wrapper {
    display: none;
  }
}
  