/* ==========================================================================
   Fields
   ========================================================================== */

/* ==========================================================================
   Default Field Label Styles
   ========================================================================== */

// // 'Above' default label
// .field-label {
//   margin: 0 0 .125em;
//   font-size: em(14);
//   text-transform: uppercase;
//   letter-spacing: .04em;
// }


// .field-label--inline {
//   float: left;
//   font-size: 0.8em;
//   line-height: 1;
//   text-transform: uppercase;
//   letter-spacing: .08em;
//   margin: .5em .25em 0 0;
// }


.inline { 
  display: inline;
  padding: 0;

  li {
    display: inline;
    list-style-type: none;
    padding: 0 1em 0 0; 
  }
}

// Display inline list fields as comma separated list. 
.inline[class^=field] {
  li {
    padding: 0;
    &:after {
      content: ', ';
    }
    &:last-child:after {
      content: '';
    }
  }
}

%inline-title {
  margin: 0;
  padding: 0 0.25em 0 0;
  font-size: em(14);
  text-transform: uppercase;
  letter-spacing: .04em;
  display: inline-block;
}

span.field-label, .field-label--inline { /* The inline field label used by the Fences module */
  @extend %inline-title;
}

.field-label--inline--wrapper {
  display: block;
  margin-bottom: 1em;
}

/* ==========================================================================
   Date Fields
   ========================================================================== */

// Inline Date Range field
.date-display-range {
  display: inline;
}


/* ==========================================================================
   WYSWIYG Special Styles
   ========================================================================== */

.callout {
  margin: 0.25em 0;
  font-size: 200%;
  @include webfont-serif;
  font-style: italic;
}