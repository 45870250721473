/* ==========================================================================
   Navigation
   ========================================================================== */
/**
 * Default Menu Styles
 */
.menu {
  list-style: none;
  padding: 0;
  margin: 0;
}
.menu__item {

}
.menu__link {
  display: block;
  text-decoration: none;
}

/**
 * Menu Toggle
 */
.menu-toggle {
  position: absolute;
  top: 2em;
  right: 0;
  color: $white;
  &:hover,
  &:focus {
    color: $white;
  }
  @include breakpoint($bp-nav-narrow) {
    width: 30px;
    height: 30px;
    @include svg-bg(menu);
    background-position: 50% 50%;
    overflow: hidden;
    text-indent: -300px;
  }
  @include breakpoint($bp-nav-middle) {
    position: static;
    float: left;
    margin-top: 3.25em;
    margin-left: 1.5em;
    &:after {
      content: ' +';
    }
  }
  @include breakpoint($bp-nav-wide) {
    display: none;
  }
}

/**
 * Nav drawer toggle
 */
.nav-toggle {
  color: $white;
  display: block;
  margin-bottom: 1em;
  @include breakpoint($bp-nav-wide) {
    display: none;
  }
}

/**
 * Main Menu (in Header region)
 */
.menu--main-menu-header {

  @include breakpoint($bp-nav-middle-and-wide) {
    position: static; // to override contextual links
    float: left;
    margin-top: 2.875em;
    margin-left: 2em;

    // Enable dropdown menu on wide screens
    .menu .menu__item:hover > .menu,
    .menu .menu__item:focus-within > .menu,
    .menu .menu__item .menu:hover {
      visibility: visible;
      opacity: 1;
      display: block;
    }
  }
  .menu {
    text-align: center;
  }
  .menu__item {
    display: inline-block;
    position: relative;

    // Second level links
    .menu {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      z-index: 100;
      transition: all 0.5s ease;
      left: 0;
      display: none;
      width: 100%;
    }
  }

  .menu__link {
    padding: .25em 0;
    display: block;
    color: $white;
  }

  .level-1 {
    > .menu__link {
      margin: 0 0.5em;
      text-transform: uppercase;
      letter-spacing: .06em;
      border-bottom: 3px solid transparent;
      @include breakpoint($bp-nav-wide) {
        font-size: em(24);
        font-weight: $font-weight-bold;
        line-height: 1;
      }
      &:hover,
      &:focus {
        border-bottom-color: rgba($white, .3);
      }
      &.is-active-trail {
        border-bottom-color: $white;
      }
    }
  }

  .level-2 {
    &.menu__item {
      display: block;
    }
    .menu__link {
      display: block;
      padding: .375em .5em;
      background-color: $teal;
      line-height: 1.2;
      font-size: 90%;
      &:hover,
      &:focus {
        background-color: darken($teal, 5%);
      }
    }
  }

  // donate link
  .level-1.last {
    @include breakpoint($bp-nav-narrow) {
      display: none;
    }
    @include breakpoint($bp-nav-wide) {
      position: absolute;
      right: em(130, 24); // space for newsletter link
      top: em(56, 24);
    }
  }
  .menu-mlid-663 .menu__link, 
  .menu__link[href*='donorperfect'] {
    color: $white;
    @include rgba-bg($teal, .7);
    padding: .5em .75em;
    border-radius: 0.5em;
    margin-right: 0;
    border-bottom-width: 0 !important;

    &:hover,
    &:focus {
      background-color: rgba($teal, .9);
    }

    @include breakpoint($bp-nav-wide) {
      font-size: em(24);
      font-weight: $font-weight-bold;
      text-transform: none;
      letter-spacing: normal;
    }
  }
}

/**
 * Newsletter Link in Header
 */
.block--newsletter-link {
  display: none;
  @include breakpoint($bp-nav-wide) {
    display: block;
    position: absolute;
    top: 2.9em;
    right: 0;
  }

  p {
    margin: 0;

    a {
      display: block;
      padding: .5em .75em;
      color: $white;
      font-weight: bold;
      font-size: em(16);
      line-height: 1;
      border-radius: 0.5em;
      &:hover,
      &:focus {
        background-color: rgba($black, .3);
      }
    }
  }
}


/**
 * Main Menu (in Navigation region for flyout nav & doesn't show on wide screens)
 */
.menu--main-menu {
  margin-bottom: 1em;
  @include breakpoint($bp-nav-wide) {
    display: none;
  }
  .menu__link {
    color: $white;
    font-size: em(18);
    &.is-active-trail {
      color: lighten($teal, 20);
    }
  }
  .level-1 > .menu__link {
    margin-top: em(10,30);
    font-size: em(30);
    font-weight: $font-weight-bold;
  }
}

/**
 * Secondary Menu & User Menu
 */
.menu--secondary-menu,
.menu--user-menu {
  margin-bottom: 1.25em;
  @include breakpoint($bp-nav-wide) {
    display: inline-block;
    margin-bottom: 0;
  }
  .menu__item {
    margin: 0 0 0.25em;
    @include breakpoint($bp-nav-wide) {
      display: inline-block;
      margin: 0 0.5em 0 0;
    }
  }
  .menu__link {
    color: $white;
    font-size: em(18);
    font-weight: $font-weight-bold;
    padding: 0 0 .25em;
    border-bottom: 2px solid rgba($white, 0);
    &:hover,
    &:focus {
      border-bottom-color: rgba($white, .3);
    }
    &.is-active-trail {
      color: lighten($teal, 20);
      // border-left: 3px solid $teal;
      // padding-left: 0.5em;
      @include breakpoint($bp-nav-wide) {
        color: $white;
        border-left: none;
        border-bottom: 2px solid;
      }
    }
    @include breakpoint($bp-nav-wide) {
      font-size: em(12);
      text-transform: uppercase;
      letter-spacing: .08em;
    }
  }
}


/**
 * Local Menu
 */
.menu--local-menu {
  position: relative;
  border-left: 0.5em solid rgba($tan-light, .6);
  padding-left: 1em;
  padding-bottom: 0.5em;
  margin-bottom: 1.5em;
  .block__title {
    @include webfont-sans;
    font-weight: $font-weight-bold;
    font-size: em(20);
    text-transform: uppercase;
    margin: 0;
    padding: 0 0 .5em;
    a {
      color: $color-text;
    }
  }
  .menu__item {
    margin: 0 0 0.5em;
    // second level
    .menu__item {
      margin-left: 0.5em;
      &:first-child {
        margin-top: .5em;
      }
      // second level
      .menu__link {
        font-size: .75em;
        text-transform: uppercase;
        letter-spacing: .03em;
        &:before {
          left: -2.7em;
          width: 0.7em;
        }
      }
    }
  }
  .menu__link {
    position: relative;
    color: $color-text;
    &:before {
      content: '';
      position: absolute;
      left: -1.5em;
      top: 0;
      display: block;
      width: 0.5em;
      height: 100%;
    }
    &:hover,
    &:focus {
      &:before {
        background-color: darken($tan-light, 5%);
      }
    }
    &.is-active-trail {
      color: $color-link;
      &:before {
        background-color: $color-link;
      }
    }
  }
}

/**
 * Breadcrumb navigation.
 */
.breadcrumb {
  @extend %clearfix;
  position: absolute;
  top: 1em;

  ol {
    margin: 0;
    padding: 0;
  }
  li {
    float: left;
    list-style-type: none;
    margin: 0 .25em 0 0;
    padding: 0;
  }
  a {
    display: inline-block;
    color: $color-text;
    font-size: em(10);
    text-transform: uppercase;
    letter-spacing: .08em;
  }
}