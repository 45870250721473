/**
 * TableDrag behavior.
 *
 * @see tabledrag.js
 */
tr.drag {
  background-color: #fffff0;
}
tr.drag-previous {
  background-color: #ffd;
}
.tabledrag-toggle-weight {
  font-size: 0.9em;
}