/* ==========================================================================
   Box sizing
   default set in global variables
   ========================================================================== */

/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: $box-sizing-default;
}
*, *:before, *:after {
  box-sizing: inherit;
}

