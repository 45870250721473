// ==========================================================================
// Helper Classes
// ==========================================================================

%all-caps {
  letter-spacing: .06em;
  text-transform: uppercase;
}

//  clearfix
%clearfix {
  &:after {
    display: block;
    content: "";
    clear: both;
  }
}

%undo-clearfix {
  &:after {
    clear: none !important;
  }
}

/* ==========================================================================
   Inner Regions
   ========================================================================== */

%inner-region {
  width: $inner-region-width;
  @include max-inner-width;
  margin-left: auto;
  margin-right: auto;
}

/* ==========================================================================
   Floats
   ========================================================================== */

%float-left {
  display: block;
  float: left;
  margin: 0 1.5em 0.5em 0;
}

%float-right {
  display: block;
  float: right;
  margin: 0 0 0.5em 1.5em;
}

/* ==========================================================================
   Buttons
   ========================================================================== */

%button {
  display: inline-block;
  padding: .7em 2em;
  border-radius: .5em;
  margin: 0 .5em .5em 0;
  text-decoration: none;
  border-width: 1px;
  border-style: solid;
  text-transform: uppercase;
  font-weight: bold;
  font-size: em(14);
  font-family: $font-family-default;
  letter-spacing: .08em;
  text-align: center;
}

%button--teal {
  @extend %button;
  color: $white;
  background-color: $teal;
  border-color: darken($teal, 5%);
  &:hover,
  &:focus {
    color: $white;
    background-color: darken($teal, 3%);
  }
}

%button--green {
  @extend %button;
  color: $white;
  background-color: $green;
  border-color: darken($green, 5%);
  &:hover,
  &:focus {
    color: $white;
    background-color: darken($green, 3%);
  }
}

%button--tan-light {
  @extend %button;
  color: $brown-dark;
  background-color: $tan-light;
  border-color: darken($tan-light, 5%);
  &:hover,
  &:focus {
    color: $brown-dark;
    background-color: darken($tan-light, 5%);
  }
}

%button--brown {
  @extend %button;
  color: $tan-light;
  background-color: $brown-dark;
  border-color: darken($brown-dark, 5%);
  &:hover,
  &:focus {
    color: $tan-light;
    background-color: darken($brown-dark, 5%);
  }
}



// ==========================================================================
// Image replacement
// ==========================================================================

%ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;/* IE 6/7 fallback */
  *text-indent: -9999px;
  &:before {
    content: "";
    display: block;
    width: 0;
    height: 150%;
  }
}

%no-bullets {
  list-style: none;
  margin-top: 0;
  margin-left: 0;
  padding-left: 0;
}

// make sure container is [position: relative]
// http://codepen.io/shshaw/full/gEiDt
%absolute-center {
  width: 50%;
  height: 50%;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
}

// Hide from both screenreaders and browsers: h5bp.com/u

%hidden {
  display: none !important;
  visibility: hidden;
}

// Hide only visually, but have it available for screenreaders: h5bp.com/v

%visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  // Extends the .visuallyhidden class to allow the element to be focusable
  // when navigated to via the keyboard: h5bp.com/p

  &.focusable:active,
  &.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}

// Hide visually and from screenreaders, but maintain layout

%invisible {
  visibility: hidden;
}
