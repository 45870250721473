/* ==========================================================================
   Node Layouts
   ========================================================================== */

/**
 * Default Non Front Layout
 */
.not-front {
  @include breakpoint($bp-middle-and-wide) {
    .region-main-inner {
      margin-left: auto;
      margin-right: auto;
      width: percentage(10/12);
    }
  }
}


/**
 * With First Sidebar
 */
.not-front.sidebar-first {
  @include breakpoint($bp-middle-and-wide) {
    .region-content {
      width: percentage(9/12);
      float: left;
      padding-right: 2em;
    }

    .sidebars {
      float: right;
      margin-top: 8em;
      width: percentage(3/12);
    }
  }
}


/**
 * Support Pages
 */
.not-front.node-type-support-page {

  .support-inner,
  .sidebars,
  .view-campaign-progress .view-content {
    @extend %inner-region;
  }

  @include breakpoint($bp-middle-and-wide) {
    .region-content {
      float: none;
      padding-right: 0;
      width: 100%;
    }

    .sidebars {
      float: none;
      margin-top: 0;
      width: 100%;
    }
  }
}

/**
 * TILT by the numbers page
 */

.not-front.page-node-215 {
  .region-content {
    float: none;
    padding-right: 0;
    width: 100%;  
  }
}
