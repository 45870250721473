/* ==========================================================================
   Views Admin Styles
   such as exposed filters, etc.
   teaser styles go in _teasers.scss
   ========================================================================== */

/**
 * Grouped Rows
 */
.title--rowgroup {
  @include webfont-serif;
  font-weight: $font-weight-normal;
  font-size: em(30);
  color: $brown-light;
  padding-top: .75em;
  border-top: 1px solid darken($tan-light, 10);
}


/**
 * Views exposed filters
 */

.view-filters {
  margin-bottom: 2em;
}

.views-exposed-widget {
  float: left; 
  margin: 0 1em 0 0;
  &.views-submit-button {
    clear: both;
  }
}