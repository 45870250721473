/* ==========================================================================
   Node Displays
   group by node type
   ========================================================================== */

// Main body field of page should contain all floats
.region-content > .field-body {
  @extend %clearfix; 
}

// Undo clearfix on specific pages where we want content below body to come up
.page-node-18 {
  .region-content > .field-body {
    @extend %undo-clearfix; 
  }
}


/* ==========================================================================
   Page Nodes
   ========================================================================== */
.not-front.node-type-page {
  .flexslider {
    @include breakpoint($bp-middle-and-wide) { 
      max-width: 50%;
      float: right;
      margin: 0 0 0.5em 1.5em;
    }
  }
}


/* ==========================================================================
   Event Nodes
   ========================================================================== */
.node-type-event {
  .group-event-meta {
    margin: -1em 0 0.5em;
    padding: 1em 0;
    color: $brown-light;
    border-bottom: 4px double lighten($brown-light, 25%);
    > *:last-child {
      margin-bottom: 0;
    }
  }
  .group-event-description {
    @extend %clearfix;
    padding: 1em 0 2em;
    margin-bottom: 2em;
    border-bottom: 4px double lighten($brown-light, 25%);
    > *:last-child {
      margin-bottom: 0;
    }
  }
  .field-event-date {
    @include webfont-serif;
    font-size: em(28);
    margin-bottom: 0.25em;
  }
  .field-location {
    @include webfont-serif;
    font-style: italic;
    font-size: em(22);
    margin-bottom: 0.25em;
  }
  .field-event-image {
    float: right;
    margin: 0.5em 0 1em 2.5%;
    width: 47.5%;
    img {
      width: 100%;
    }
  }
  .field-related-preserve {
    list-style: none;
    padding: 0;
    margin-bottom: 2em;
  }
  .field-register-link {
    clear: left; // could be floated inline image
    padding-top: 1.5em;

    a {
      @extend %button--teal;
    }
  }
}

/* ==========================================================================
   Preserve Nodes
   ========================================================================== */
.node-type-preserve {

  // Short Intro
  // using child selector because there is other .field-short-summary on page
  .region-content > .field-short-summary {
    @include webfont-serif;
    font-size: em(20);
    font-style: italic;
    color: $brown-light;
  }
  .flexslider {
    margin: 2em 0;
    padding: 1.5em 0 0;
    border-top: 4px double lighten($brown-light, 25%);
    border-bottom: 4px double lighten($brown-light, 25%);
  }

  .field-geofield {
    margin: 2em 0;
  }
}

/* ==========================================================================
   Project Nodes
   ========================================================================== */
.node-type-project {
  
  // Short Intro
  // using child selector because there is other .field-short-summary on page
  .region-content > .field-project-summary {
    @include webfont-serif;
    font-size: em(20);
    font-style: italic;
    color: $brown-light;
  }
  .flexslider {
    margin: 2em 0;
    padding: 1.5em 0 0;
    border-top: 4px double lighten($brown-light, 25%);
    border-bottom: 4px double lighten($brown-light, 25%);
  }

  // Related Preserves
  .view-preserves {
    margin-top: 3em; // offset from content
  }
}

/* ==========================================================================
   FAQ Nodes
   ========================================================================== */
.node-type-faq {
  .region-content > .field-body {
    margin-bottom: 3em;
  }
}

/* ==========================================================================
   News Nodes
   ========================================================================== */
.node-type-news {
  .region-content > .field-body {
    margin-bottom: 2em; // separate from other fields
  }

  // More News block
  .block--news {
    margin-top: 3em; // separate from node content
  }

  // Slideshow
  .flexslider {
    @include breakpoint($bp-middle-and-wide) { 
      max-width: 50%;
      float: right;
      margin: 0 0 0.5em 1.5em;
    }
  }
}

/* ==========================================================================
   Support Page Nodes
   ========================================================================== */
.node-type-support-page {

  // Default Section Header
  .support-section-title {
    @include webfont-serif;
    font-size: 2.5em;
    font-weight: normal;
    line-height: 1.1;
    text-align: center;
    font-style: italic;
  }

  // Page title and intro text
  .support-outer--intro {
    padding-top: 3em;
    padding-bottom: 3em;
    text-align: center;
    color: $white;
    background-color: $color-background-footer;
    background-image: image('logo-black20.png');
    background-position: 50% top;
    background-size: auto 95%;
    background-repeat: no-repeat;
  }

  // Page Title
  .support-page-title {
    margin-bottom: 0.25em;
    font-style: italic;
    letter-spacing: -0.025em;
    font-size: 3.5em;
    @include breakpoint($bp-middle) {
      font-size: 4.5em;
    }
    @include breakpoint($bp-wide) {
      font-size: 5.5em;
    }

    span {
      margin: 0 0 1.5em;
      display: block;
      text-transform: uppercase;
      font-style: normal;
      font-size: 13px;
      letter-spacing: 0.3em;
    }
  }

  // intro para
  .support-intro {
    font-size: 1.4em;
    color: $teal;
  }

  // Support Buttons (centered)
  .support-link-text {
    text-align: center;
  }

  /**
   * Reasons to Support
   */
  .support-outer--reasons {
    padding: 4.5em 0;
    background-color: mix($tan-light, $white);
  }

  // <ol>
  .support-reasons {
    list-style: none;
    margin: 0;
    padding: 0;
    @include breakpoint($bp-middle-and-wide) {
      display: flex;
    }

    li {
      margin-bottom: 1.5em;
      background-color: $white;
      box-shadow: 0 2px 3px rgba($black,.2);
      @include breakpoint($bp-middle-and-wide) {
        width: 32%;
        margin: 0 2% 0 0;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    figure {
      margin: 0;
      position: relative;
    }

    figcaption {
      margin: 0;
      padding: .5em .75em;
      text-align: right;
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: rgba($black,.3);
      color: $white;
      font-size: em(13);
      line-height: 1;
    }

    h3 {
      margin: 0;
      padding: 1em;
      font-size: em(18);
      line-height: 1.1;
    }
  }

  /**
   * Goals
   */
  .view-campaign-progress {
    padding: 3em 1em 1em;
    position: relative;
    text-align: center;
    background-color: $white;
    &:after {
      content: '';
      position: absolute;
      bottom: -20px;
      left: 50%;  
      margin-left: -30px;
      width: 0; 
      height: 0; 
      border-left: 30px solid transparent;
      border-right: 30px solid transparent;
      border-top: 30px solid $white;
    }
    h2 {
      margin-bottom: .25em;
      @include webfont-serif;
      font-size: 2.5em;
      font-weight: normal;
      line-height: 1.1;
      font-style: italic;
    }
  }
  .progress-bar {
    margin: 0 0 0.5em;
    height: 50px;
    background-color: desaturate(lighten($teal, 50%), 30%);
  }
  .progress-bar-amount {
    background-color: $teal;
    height: 100%;
    width: 0%;
    max-width: 100%;
  }
  .support-amount-raised {
    text-transform: uppercase;
    letter-spacing: 0.08em;
    font-weight: bold;
    .js & {
      opacity: 0;
      transition: opacity 1.5s ease-out;
    }
    &.is-visible {
      opacity: 1;
    }
  }
  .support-outer--goals {
    padding: 5em 0 3em;
    background-color: $tan-light;
  }

  .support-goals {
    @extend %clearfix;
    margin-bottom: 2em;
    @include breakpoint($bp-wide) {
      display: flex;
    }
  }
  .support-goal {
    padding: 1em;
    background-color: $white;
    box-shadow: 0 2px 3px rgba($black,.2);
    @include breakpoint($bp-middle) {
      width: 48%;
      float: right;
      margin-bottom: 1.5em;
      &:nth-child(odd) {
        float: left;
        clear: both;
      }
    }
    @include breakpoint($bp-wide) {
      width: 23.5%;
      margin: 0 2% 0 0;
      &:last-child {
        margin-right: 0;
      }
    }

    h3 {
      font-size: 1.1em;
      line-height: 1.2;
    }
    p {
      font-size: 0.9em;
      line-height: 1.3;
    }
    table {
      font-size: 0.75em;
    }
    tr {
      border-bottom: none;
      border-top: 1px solid rgba($black, .1);
    }
    td {
      padding: .5em 0.5em .5em 0;
      line-height: 1.1;
    }
  }

  /**
   * Donate Link
   */
  .support-outer--donate {
    background-image: image('donate-bg-1200.jpg');
    background-size: cover;
    background-position: 50% 50%;

    .support-inner {
      position: relative;
      height: 20em;
    }

    .support-donate-link {
      position: absolute;
      text-align: center;
      top: 50%;
      width: 100%;
  
      a {
        background-color: rgba($teal,.8);
        border: .125em solid rgba($black,.4);
        padding: 0.75em 2em;
        color: $white;
        border-radius: .25em;
        font-size: 1.2em;
        font-weight: bold;
        &:hover,
        &:focus {
          background-color: rgba($teal,.9);
        }
      }
    }
  }

  /**
   * Naming
   */
  .support-outer--naming {
    padding: 4em 0 2em;

    p {
      margin: 0 auto 1em;
      max-width: 40em;
      @include webfont-serif;
      font-size: 1.125em;
    }
  }

  /**
   * Legacy
   */
  .support-outer--legacy {
    padding: 3em 0 4em;

    p {
      margin: 0 auto 1em;
      max-width: 40em;
      @include webfont-serif;
      font-size: 1.125em;
    }
  }
  
  
}
