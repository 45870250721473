/* ==========================================================================
   Home Page Styles
   ========================================================================== */

.front {
  
  .region-content {
    background-color: #ebf7f6;
    
    .page__title {
      @include webfont-sans;
      font-weight: $font-weight-bold;
      font-size: em(40);
      color: $blue;
      text-align: center;
    }
    .field-body {
      font-size: em(22);
      @include webfont-serif;
      margin: 0 auto;
      max-width: 38em;
      text-align: justify;
    }
  }
}


.region-home-family {
  display: block;
  justify-content: center;
  min-height: 400px;
  @include breakpoint($desk){
    display: flex;
    min-height: auto;
  }
  > .block--home-family {
    flex: 0 0 50%;
  }
  .blockgroup {
    flex:0 0 50%;
    @extend %clearfix;
    background-color: $tan-light;
    margin: 0 auto;
    text-align: center;
    padding: 2em 3em;
  }
}

.block--home-family {
  position: relative;
  padding: 2em 2em 2.5em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  text-align: center;
  color: $white;

  &:before {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(#000, .3), rgba(#000, .9));
    @include breakpoint($desk){
      background-image: linear-gradient(to bottom, transparent 10%, rgba(#000, .9));
    }
  }

  .block__content {
    position: relative;
    z-index: 3;
  }

  .field-link-family {
    margin-bottom: 0;
  }
}

.block--home-family__title {
  margin-top: 1.5em;
  color: $white;
}

