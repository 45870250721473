// ==========================================================================
// $Print
// Inlined to avoid required HTTP connection: h5bp.com/r
// ==========================================================================

@media print {
  * {
      background: transparent !important;
      color: #000 !important; // Black prints faster: h5bp.com/s
      box-shadow: none !important;
      text-shadow: none !important;
  }

  // Underline all links.
  a:link,
  a:visited {
  text-decoration: underline !important;
  }

  a[href]:after {
      content: " (" attr(href) ")";
  }

  abbr[title]:after {
      content: " (" attr(title) ")";
  }


  // * Don't show links for images, or javascript/internal links
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
      content: "";
  }

  pre,
  blockquote {
      border: 1px solid #999;
      page-break-inside: avoid;
  }

  thead {
      display: table-header-group; // h5bp.com/t
  }

  tr,
  img {
      page-break-inside: avoid;
  }

  img {
      max-width: 100% !important;
  }

  @page {
      margin: 0.5cm;
  }

  p,
  h2,
  h3 {
      orphans: 3;
      widows: 3;
  }

  h2,
  h3 {
      page-break-after: avoid;
  }

  /* Hide sidebars and nav elements. */
  #skip-link,
  .region-footer,
  .breadcrumb,
  .tabs,
  .action-links,
  .links,
  .book-navigation,
  .forum-topic-navigation,
  .pager,
  .feed-icons {
    visibility: hidden;
    display: none;
  }
}
