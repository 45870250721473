/* ==========================================================================
   Page Regions
   define colors, backgrounds for main page wrappers
   ========================================================================== */

html {
  background-color: $color-background-html;
}

/**
 * Header
 */
.region-header {
  padding-top: 1em;
  padding-bottom: 0.5em;
  transition: background-color .3s ease-in;
  background-color: rgba($color-background-header, 0);
  @include breakpoint($bp-nav-wide) {
    .no-js &,
    &.fixed {
      background-color: rgba($color-background-header, .95);
    }
  }
}

/**
 * Banner
 */
.region-banner {
  position: relative; // context for before and after
  color: $white;
  background-color: $color-background-header;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  padding-top: 160px;

  // Before and after elements should cover entire region
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  // use gradient to darken areas where text are
  &:after {
    background-image: linear-gradient(rgba($black, .4), rgba($black, .1) 75%, rgba($black, .7));
  }
  // cover with solid color until image loads
  &:before {
    z-index: 2;
    opacity: 1;
    background-color: $color-background-header;
    transition: opacity cubic-bezier(.22,.61,.36,1) 1s;
  }
  // Class added by js when image starts loading
  &.bg-loaded {
    &:before {
      opacity: 0;
    }
  }

  .block {
    position: relative;
    z-index: 5;
  }

  // Support Pages
  .node-type-support-page & {
    background-color: $color-background-footer;
    // background-image: image('TILT-pattern-dark.png');
    // background-repeat: repeat;
    // background-size: initial;
    // background-position: -30px 0;
    &:before,
    &:after {
      display: none;
    }
  }
}

/**
 * Navigation Region
 */
.region-navigation {
  &.nav-open {
    @include breakpoint($bp-nav-narrow-and-middle) {
      padding: 1.5em;
      // background-color: rgba(0,0,0,.1);
      // box-shadow: 0.25em 0 0.5em rgba(0,0,0,.2) inset;
    }
  }
}

/**
 * Highlighted Region
 */
.region-highlighted {
  padding: 1.5em 0;
  color: $white;
  background-color: $color-background-html;
}

/**
 * Main Content Region Wrapper
 */
.region-main {
  background-color: $color-background;
  .front & {
    //background-color: $tan-light;
    background-image: image('dark-river.jpg');
    background-repeat:no-repeat;
    background-size:cover;
    background-position:top center;
  }
}

/**
 * Main Content
 */
.region-content {
  padding-top: 3em; // top spacing and we'll position breadcrumbs here
  padding-bottom: 3em;

  .node-type-support-page & {
    padding-top: 0;
    padding-bottom: 0;
  }
}

/**
 * Homepage Family Section
 */

.region-home-family {
  margin: 0 auto;
  @include breakpoint($tablet){
    max-width: $tablet;
  }
  @include breakpoint($desk){
    max-width: $desk;
  }
  @include breakpoint($wide){
    max-width: $wide;
  }
}

/**
 * Stay Connected
 */
.region-stay-connected {
  padding-top: 2.5em;
  padding-bottom: 2.5em;
  background-color: $color-background-stay-connected;
}

/**
 * Footer
 */
.region-footer {
  padding-top: 2em;
  padding-bottom: 2em;
  color: $color-text-footer;
  background-color: $color-background-footer;
}

