/* ==========================================================================
   Accessibility Features
   ========================================================================== */


/* element-invisible as defined by http://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
.element-invisible,
%element-invisible {
  @include element-invisible;
}

/* Turns off the element-invisible effect. */
%element-invisible-off {
  @include element-invisible-off;
}

.element-focusable,
%element-focusable {
  @extend %element-invisible;

  &:active,
  &:focus {
    @extend %element-invisible-off;
  }
}

/*
 * The skip-link link will be completely hidden until a user tabs to the link.
 */
#skip-link {
  margin: 0;

  a,
  a:visited {
    display: block;
    width: 100%;
    padding: 2px 0 3px 0;
    text-align: center;
    background-color: #666;
    color: #fff;
  }
}