/* ==========================================================================
   Site Header Components
   ========================================================================== */


/**
 * Logo
 */
.header__site-name {
  margin: 0;
  text-align: center;
  line-height: 1;
  @include breakpoint($bp-nav-middle-and-wide) {
    float: left;
  }
}
.header__site-link {
  display: inline-block;
  width: 166px;
  height: 105px;
  text-indent: -9999px;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  @include svg-bg('logo-web-white');
}

/**
 * Header Image View
 */
.block--header-image {
  padding: 0 20px;
}
.header-image-caption {
  position: relative;
  z-index: 10;
  margin: 0 0 0 auto;
  padding: 2em 0 0.625em;
  text-align: right;
  font-size: em(12);
  font-weight: $font-weight-bold;
  color: $white;

  a {
    margin-left: .25em;
    display: inline-block;
    // color: #23df8a;
    color: lighten($teal, 15%);
    text-transform: uppercase;
    letter-spacing: .04em;
    font-weight: $font-weight-bold;
    &:hover,
    &:focus {
      color: lighten($teal, 30%);
    }
  }
}