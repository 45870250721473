/* ==========================================================================
   Search
   ========================================================================== */


/**
 * Search Toggle
 */
.search-toggle {
  @include breakpoint($bp-nav-narrow-and-middle) {
    display: none;
  }
}

/**
 * Search Block
 */
.block-search {
  @include breakpoint($bp-nav-wide) {
    display: inline-block;
  }
  .form-text {
    border: none;
    background-color: rgba($black,.3);
    @include svg-bg('search');
    background-position: .5em 50%;
    background-repeat: no-repeat;
    background-size: auto 1em;
    transition: width 0.2s ease-in-out;
    width: 100%;
    padding-left: 2em;
    @include breakpoint($bp-nav-wide) {
      width: 1.5em;
      background-color: transparent;
      padding-left: 0;
      &:focus,
      &:active,
      &:valid {
        width: 12em;
        padding-left: 2em;
        background-color: rgba($black,.3);
      }
    }
  }
  .form-actions {
    @include element-invisible();
  }
}