/* ==========================================================================
   Default Link Styles
   ========================================================================== */


a {
  color: $color-link;
  text-decoration: none;
  transition: all .3s ease-in;
  &:hover,
  &:focus {
    color: $color-link-hover;
  }
}