/* ==========================================================================
   Regions Layout
   ========================================================================== */

html, 
body {
  width: 100%;
  overflow-x: hidden;
}

.region-page {
  position: relative;
  transition: transform 0.2s ease-in-out;
  &.nav-open {
    @include breakpoint($bp-nav-narrow-and-middle) {
      transform: translateX(-1 * $nav-drawer-width);
      // .no-csstransforms & {
      //   left: (-1 * $nav-drawer-width);
      // }
    }
  }
}

/**
 * Header (taken out of page flow, fixed on wide screens)
 */
.region-header {
  position: absolute; 
  top: 0;
  left: 0;
  width: 100%;
  z-index: 900;

  @include breakpoint($bp-nav-wide) {
    position: fixed;
  }

  .admin-menu & {
    top: 30px;
  }

  .admin-menu-with-shortcuts & {
    top: 64px;
  }
}
// .region-header-inner {
//   z-index: 2;
// }

.region-navigation {
  .js & {
    @include breakpoint($bp-nav-narrow-and-middle) {
      width: $nav-drawer-width;
      position: absolute;
      top: -1em;
      right: (-1 * $nav-drawer-width);
      margin-right: -2.6%; // just over half of outer margin (inner region is 95% width)
    }
  }
  // &.nav-open {
  //   @include breakpoint($bp-nav-narrow-and-middle) {
  //     width: $nav-drawer-width;
  //   }
  // }
  @include breakpoint($bp-nav-wide) {
    min-height: 0 !important; // override js for wide nav
    position: absolute;
    top: -0.125em;
    left: 0;
    width: 100%;
    text-align: right;
  }
}

.region-header-inner,
.not-front .region-main-inner,
.region-footer-inner,
.region-stay-connected-inner,
.region-highlighted-inner {
  @extend %inner-region;
  @extend %clearfix;
  position: relative;
}

/**
 * Support Pages
 */
.not-front.node-type-support-page .region-main-inner {
  width: 100%;
  max-width: none;
}
