/* ==========================================================================
   Stay Connected Blocks (social links and newsletter signup)
   ========================================================================== */

%social-icon-link {
  color: $white;
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 0.5em;
  text-indent: -9999px;
  overflow: hidden;
  background-color: $blue;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.menu--social-menu {

  .block__title {
    color: $white;
    text-align: center;
    font-size: em(36);
  }

  .menu {
    list-style: none;
    text-align: center;
    padding: 0;
    margin: 0 0 1em;
  }

  .menu__item {
    display: inline-block;
    margin: 0 .125em;
  }

  .menu__link {
    // Social Icons
    &[href*="facebook.com"] {
      @extend %social-icon-link;
      @include svg-bg('icon-facebook');
    }
    &[href*="twitter.com"] {
      @extend %social-icon-link;
      @include svg-bg('icon-twitter');
    }
    &[href*="flickr.com"] {
      @extend %social-icon-link;
      @include svg-bg('icon-flickr');
    }
    &[href*="youtube.com"] {
      @extend %social-icon-link;
      @include svg-bg('icon-youtube');
    }
    &[href*="instagram.com"] {
      @extend %social-icon-link;
      @include svg-bg('icon-instagram');
    }
    &[href*="contact"] {
      @extend %social-icon-link;
      @include svg-bg('icon-contact');
    }
    &[href*="amazon.com"] {
      @extend %social-icon-link;
      @include svg-bg('icon-amazon');
    }
  }
}


/**
 * Constant Contact Signup Form
 */
.block-constant-contact {
  .block__title {
    text-align: center;
    font-size: em(18);
    text-transform: uppercase;
    letter-spacing: .08em;
    color: $white;
  }
  .form-item {
    @include breakpoint($bp-middle) {
      width: 32%;
      float: left;
      margin-right: 2%;
      &.form-item-cc-email-1 {
        margin-right: 0;
      }
    }
    @include breakpoint($bp-wide) {
      width: 23%;
      float: left;
      margin-right: 2%;
    }
  }
  label {
    color: $blue;
    font-size: em(12);
    text-transform: uppercase;
    letter-spacing: .08em;
  }
  .form-text {
    width: 100%;
    @include rgba-bg($white, .3);
    border-color: rgba($white, .1);
    &:focus {
      @include rgba-bg($white, .7);
    }
  }
  .form-submit {
    width: 100%;
    margin: 0.75em 0 0;
    color: rgba($teal, .95);
    background-color: rgba($white, .8);
    border-color: rgba($white, .2);
    @include breakpoint($bp-middle) {
      width: 32%;
      margin: 1em auto;
      display: block;
    }
    @include breakpoint($bp-wide) {
      width: 22%;
      margin-top: 1.2em;
    }
  }
}

