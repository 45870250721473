/* ==========================================================================
   Tables
   ========================================================================== */

table {
  font-size: em(14);
  width: 100%;
  max-width: 100%;

  caption {
    font-size: em(18);
    @include webfont-serif;
    font-style: italic;
    margin-bottom: 0.5em;
  }
}

th {
  text-align: left;
  padding: .6em 1em .6em 0;
}

td {
  padding: .6em 1em .6em 0;
}

tr {
  border-bottom: 1px solid $tan-light;
}