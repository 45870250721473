// ==========================================================================
// = Global Variables
// ==========================================================================


/**
 * Breakpoint
 * @link https://github.com/at-import/breakpoint
 */
@import '../bower_components/compass-breakpoint/stylesheets/breakpoint';


/**
 * CSS layout debugging
 * @link http://pesticide.io/
 * @type boolean
 */

$pesticide-debug              : false;


// Color Scheme
// =============

// Descriptive colors:
$teal                         : rgb(35,168,158);
$green                        : rgb(0,171,103);
$blue                         : rgb(35,80,101);
$brown-dark                   : rgb(76,61,55);
$brown-mid                    : rgb(110,71,58); // tilt kids brown
$brown-light                  : rgb(193,153,110);
$brown-superlight			        : rgb(213,173,130);
$tan-light                    : rgb(248,243,238);
$white                        : #fff;
$black                        : #000;
$orange                       : #e7a008;


// Common colors
$color-background             : $white;
$color-background-header      : $brown-dark; // fallback if image doeesn't load;
$color-background-tilt-kids   : #ebf7f6;
$color-background-stay-connected : #97cdc9;
$color-background-footer      : #174256;
$color-background-html        : $color-background-footer;

$color-text                   : $brown-dark;
$color-text-footer            : $white;


// Links
$color-link                   : $teal;
$color-link-hover             : lighten($teal, 15);
$color-link-visited           : $teal;


// Selections
$color-selection              : #b3d4fc;

// Messages
$color-status                 : rgb(76,157,42);
$color-warning                : rgb(250,220,59);
$color-error                  : red;


// Tabs
$tabs-link-active: $brown-dark;
$tabs-link-inactive: $color-link;
$tabs-link-inactive-hover: $color-link-hover;
$tabs-border: #bbb;


/**
 * Base font size in used in _mixins.scss
 * @type number
 */

$base-font-size-value         : 16;
$base-font-size               : $base-font-size-value * 1px ;

/**
 * Base line-height in used in _mixins.scss
 * @type number
 */

$base-line-height-ratio       : 1.4;
$base-line-height             : ( $base-font-size-value * $base-line-height-ratio ) * 1px;


// Font Stacks
// ===========

/**
 * (optional) URL for Google Fonts import
 * @type string
 */

// $google-fonts-url          : 'http://fonts.googleapis.com/css?family=Source+Sans+Pro|Source+Code+Pro';

/**
 * (optional) names of webfonts imports
 * @type array
 */

// $fonts                     : 'FONTNAME', 'FONTNAME', 'FONTNAME', 'FONTNAME';

/**
 * define font stack used for sans-serifs
 * @type string
 */

$font-family-sans             : "franklin-gothic-urw", 'Franklin Gothic', 'ITC Franklin Gothic', sans-serif;
$font-family-fallback-sans     : 'Franklin Gothic', 'ITC Franklin Gothic', sans-serif;

/**
 * define font stack used for serifs
 * @type string
 */

$font-family-serif             :  "adobe-caslon-pro", 'Adobe Caslon Pro', 'Big Caslon', 'Hoefler Text', 'Book Antiqua', 'Palatino Linotype', Georgia, serif;
$font-family-fallback-serif    : 'Adobe Caslon Pro', 'Big Caslon', 'Hoefler Text', 'Book Antiqua', 'Palatino Linotype', Georgia, serif;

/**
 * define font stack used for monospaced copy
 * @type string
 */

$font-family-monospace        : 'Inconsolata', "Menlo", Consolas, 'Andale Mono WT', 'Andale Mono', 'Lucida Console',
                                'Lucida Sans Typewriter', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Liberation Mono', 'Nimbus Mono L',
                                Monaco, 'Courier New', Courier, monospace;

/**
 * define font stack used for headings
 * @requires {variable} $font-family-serif
 * @type string
 */

$font-family-display          : $font-family-serif;

/**
 * define font stack used for paragraphs
 * @requires {variable} font-family-sans
 * @type string
 */

$font-family-default          : $font-family-sans;
$font-family-fallback         : $font-family-fallback-sans;

// Fonts Loaded Class (on html element)
// ============

$fonts-loaded-class : '.wf-active';

// Font Weights
// ============

$font-weight-light            : 300;
$font-weight-normal           : 400;
$font-weight-bold             : 700;
$font-weight-heavy            : 900;


// Max content area / inner regions width
// ============
$max-inner-region-width-px    : 1200px;
$max-inner-region-width-rem   : (1200/16) * 1rem;
$inner-region-width           : 95%;
$outer-region-breakpoint      : (1200/.95) / 16 * 1em; // out width when inner hits max


// Navigation drawer width
// ============
$nav-drawer-width             : 300px;


// Breakpoints
// Define your own breakpoints to suit the design
// @link http://breakpoint-sass.com/
// ============

@include breakpoint-set('to ems', true);
@include breakpoint-set('no query fallbacks', true);

$tablet: 600px;
$desk: 900px;
$wide: 1200px;

$bp-narrow-end: (630/16) * 1em; 
$bp-middle-start: (631/16) * 1em; 
$bp-middle-end: (999/16) * 1em; 
$bp-wide-start: (1000/16) * 1em; 

$bp-narrow: max-width $bp-narrow-end;
$bp-middle: $bp-middle-start $bp-middle-end;
$bp-wide: $bp-wide-start, 'no-query' '.lt-ie9';

$bp-narrow-and-middle: max-width $bp-middle-end;
$bp-middle-and-wide: $bp-middle-start, 'no-query' '.lt-ie9';

// navigation breakpoints
$bp-nav-narrow-end: (700/16) * 1em; 
$bp-nav-middle-start: (701/16) * 1em; 
$bp-nav-middle-end: (900/16) * 1em; 
$bp-nav-wide-start: (901/16) * 1em;

$bp-nav-narrow: max-width $bp-nav-narrow-end;
$bp-nav-middle: $bp-nav-middle-start $bp-nav-middle-end;
$bp-nav-wide: $bp-nav-wide-start, 'no-query' '.lt-ie9';

$bp-nav-narrow-and-middle: max-width $bp-nav-middle-end;
$bp-nav-middle-and-wide: $bp-nav-middle-start, 'no-query' '.lt-ie9';

$bp-tilt-kids-wide: 55.375em, 'no-query' '.lt-ie9';


// CSS Box Model
// @link: http://www.paulirish.com/2012/box-sizing-border-box-ftw/
// @type string
// ===========
$box-sizing-default: border-box;


